<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />

      <div style="width: 30px"></div>

      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <view-payslip />
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import ViewPayslip from "@/components/ViewPayslip";

export default {
  components: {
    BackButton,
    Breadcrumb,
    ViewPayslip
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Payslip",
          href: "EssPayslip",
          id: "Payrun"
        },
        {
          disabled: false,
          text: "View Payslip",
          id: "View Payslip"
        }
      ]
    };
  }
};
</script>
<style scoped>
.bordered {
  border: 1px solid #878e99;
}
</style>
